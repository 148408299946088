.search-box__main {
  .search-box--wrapper > div {
    background: $light-black-1;
    border-radius: 0.625rem;
    .icon-group {
      opacity: 1 !important;
    }
    .post-group {
      font-size: 1.3rem;
      cursor: pointer;
    }
    .search-box--input {
      border: 1px solid $primary-bg-color;
      &:focus {
        border-color: transparent;
      }
    }
  }
}
