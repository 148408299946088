.station-details__main {
  .station-info--box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    .station-info-content {
      .station-info__inner {
        display: flex;
        .station-icon--block {
          .station-icon {
            padding: 1.2rem 1.1rem;
            background: rgba(60, 124, 221, 0.1);
            border-radius: 5px;
            margin-right: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              height: 1.6rem;
              width: 1.8rem;
              color: #3c7cdd;
            }
          }
        }
        .station-info--wrapper {
          .station-status--block {
            display: flex;
            align-items: center;
            .info-name {
              display: flex;
              align-items: center;
              margin: 0.7rem;
              font-style: normal;
              font-weight: 500;
              font-size: 1.625rem;
              line-height: 2.063rem;
              color: $input-value-color;
            }
            .station-status-block--inner {
              display: flex;
              align-items: center;
              flex-direction: column;
              margin-left: 1rem;
              .station-status {
                &.status-color-red {
                  color: #a5b6c6;
                  font-size: 1rem;
                  font-weight: 500;
                  .status-text {
                    font-size: 0.95rem;
                    padding-bottom: 1rem;
                  }
                }
                &.status-color-green {
                  color: #14ac7f;
                  font-size: 1rem;
                  font-weight: 500;
                  .status-text {
                    font-size: 0.9rem;
                  }
                }
              }
            }
          }
          .station-info-rating--block {
            display: flex;
            align-items: center;
            .rating-star-block {
              margin-right: 1rem;
              svg {
                margin-right: 0.2rem;
              }
            }
            .rating-count {
              font-weight: normal;
              font-style: normal;
              font-size: 0.875rem;
              line-height: 1.125rem;
              color: $input-value-color;
            }
          }
        }
      }
    }
    .station-dropdown_btn {
      .dropdown-icon > svg {
        color: #5f7384;
        font-size: 2rem;
      }
      .delete--station {
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
          font-size: 1.2rem;
        }
        .delete-station-text {
          font-style: normal;
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 1.125rem;
          color: #e12f17;
          padding-left: 1rem;
        }
      }

      .edit--station {
        display: flex;
        align-items: center;
        cursor: pointer;

        svg {
          font-size: 1.2rem;
        }

        .edit-station-text {
          font-style: normal;
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 1.125rem;
          color: #ffffff;
          padding-left: 1rem;
        }
      }
    }
  }
  .loader--block {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
//general tab
.general-details__main {
  .general_layout {
    display: flex;
    .general-details--inner {
      flex: 1;
      .general-details__block {
        margin: 1.5rem;
        .general-details--wrapper {
          margin: 1.5rem;
        }
      }
    }
    .general-info__main {
      flex: 1;
      margin-top: 1.5rem;
      .general-info__main-wrapper {
        .operational-timing--block {
          .operational-timing-inner {
            background: #1b2a38;
            border: 1px solid #2f3c47;
            box-sizing: border-box;
            border-radius: 10px;
            margin-bottom: 1rem;
            padding: 1.5rem 1.2rem;
            .operation-timing-text {
              margin-bottom: 1.5rem;
              .timing-text-inner {
                color: #8d949b;
              }
            }
            .operation-timing__content {
              display: flex;
              align-items: center;
              .operation-timing--icon {
                margin-right: 0.5rem;
                .img,
                svg {
                  color: #3c7cdd;
                  height: 1.5rem;
                  width: 1.5rem;
                }
              }
              .operation--time {
                .operation-time-text {
                  font-size: 0.875rem;
                  color: $input-value-color;
                }
              }
            }
          }
        }
        .contact-person-details__main--inner {
          background: #1b2a38;
          border: 1px solid #2f3c47;
          box-sizing: border-box;
          border-radius: 10px;
          margin: 0 0 1rem 0;
          padding: 1.5rem 1.2rem;
          .details-heading--block {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .details-heading {
              color: #8d949b;
              margin-bottom: 1.5rem;
            }
          }
          .contact-info__main {
            .contact-info--block-item {
              margin-bottom: 0.5rem;
              display: flex;
              align-items: center;
              font-weight: 400;
              font-size: 0.95rem;
              .contact-info---icon {
                height: 1.2rem;
                width: 1.2rem;
                color: #8d9dac;
                margin-right: 0.875rem;
              }
              .info--label {
                margin-right: 0.7rem;
                color: rgba(255, 255, 255, 0.5);
              }
              .info--text {
                color: $input-value-color;
              }
            }
          }
        }
      }
      .address__box {
        .address__box--inner {
          background: #1b2a38;
          border: 1px solid #2f3c47;
          box-sizing: border-box;
          border-radius: 10px;
          margin-bottom: 1rem;
          padding: 1.5rem 1.2rem;
          .address-heading {
            direction: ltr;
            color: #8d949b;
            margin-bottom: 1rem;
          }
          .station-address {
            direction: ltr;
            font-size: 0.95rem;
            color: $input-value-color;
            margin-bottom: 1rem;
          }
          .station-map-img {
            height: 20rem;
            width: 100%;
            .map-image {
              height: 100%;
              width: 100%;
              border-radius: 6px;
            }
            .station-marker-text {
              color: black;
            }
          }
        }
      }
    }
  }
}
//chargers tab
.chargers-details__main {
  .charger-details--tabs {
    display: flex;
    align-items: center;
    .charger-status-tab {
      padding: 0.8rem 2.4rem 0.8rem 1.4rem;
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 1.25rem;
      color: #8d949b;
      margin-right: 1rem;
      cursor: pointer;
      &.active {
        background: rgba(60, 124, 221, 0.13);
        border-radius: 8px;
        padding: 0.8rem 2.4rem 0.8rem 1.4rem;
        color: $input-value-color;
        font-size: 0.9rem;
        margin-right: 1.125rem;
        font-style: normal;
        font-weight: normal;
      }
      .charger-count--badge {
        display: inline-block;
        padding: 0.3rem 0.4rem;
        font-size: 0.75rem;
        font-weight: 700;
        line-height: 1;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 1rem;
        background: #0f9b72;
        margin-left: 0.5rem;
      }
      .draft-badge {
        background: #3c7cdd;
      }
    }
    // .draft-tab {
    //   padding: 0.8rem 2.4rem 0.8rem 1.4rem;
    //   font-style: normal;
    //   font-weight: normal;
    //   font-size: 1rem;
    //   line-height: 1.25rem;
    //   color: #8d949b;
    //   margin-right: 1rem;
    //   cursor: pointer;
    //   &.active {
    //     background: rgba(60, 124, 221, 0.13);
    //     border-radius: 8px;
    //     padding: 0.8rem 2.4rem 0.8rem 1.4rem;
    //     color: $input-value-color;
    //     font-size: 0.875rem;
    //     margin-right: 1.125rem;
    //     font-style: normal;
    //     font-weight: normal;
    //   }
    //   .draft-badge {
    //     display: inline-block;
    //     padding: 0.4em 0.65em;
    //     font-size: 0.75rem;
    //     font-weight: 700;
    //     line-height: 1;
    //     color: #fff;
    //     text-align: center;
    //     white-space: nowrap;
    //     vertical-align: baseline;
    //     border-radius: 1rem;
    //     background: #3c7cdd;
    //     margin-left: 0.5rem;
    //   }
    // }
  }
  .charger-list--block {
    margin-top: 1rem;
    .charger--list {
      background: #1b2a38;
      border: 1px solid #2f3c47;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 1rem 1.5rem;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      .list-info {
        cursor: pointer;
        margin-right: 1.5rem;
        svg {
          font-size: 1.5rem;
        }
        .list-name {
          font-style: normal;
          font-weight: 500;
          font-size: 1rem;
          line-height: 1.25rem;
          color: $input-value-color;
          margin-left: 1rem;
        }
      }
      .charger-status--block {
        display: inline-flex;
        align-items: center;
        margin-left: 1rem;
        .charger-details--status {
          display: flex;
          align-items: center;
          flex-direction: column;
          .disabled-list-text {
            color: #a5b6c6;
            font-style: normal;
            font-weight: 500;
            font-size: 0.9rem;
          }
          .list-dot-icon {
            display: inline-flex;
            font-size: 1.5rem;
          }
          .list-text {
            font-style: normal;
            font-weight: 500;
            font-size: 0.9rem;
          }
        }
      }
      .plug-info--block {
        margin-left: 1rem;
        .plug-info {
          padding-right: 0.8rem;
        }
        .plug-info-wrapper {
          .plug-info--inner {
            background: #1b2a38;
            border: 1px solid #2f3c47;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 1.1rem 1.5rem;
            display: flex;
            align-items: center;
            .plug-info-content {
              display: flex;
              align-items: center;
              margin-right: 1rem;
              .charger-round_icon {
                margin-right: 0.7rem;
                svg {
                  color: #3c7cdd;
                  height: 1.5rem;
                  width: 1.5rem;
                }
              }
            }
          }
        }
      }
      .charger-list--button {
        margin-left: auto;
        .charger-action--btn {
          width: 7.5rem;
          border-radius: 5px;
          border: none;
          font-weight: 400;
          font-size: 0.85rem;
          padding: 0.7rem;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}
.charger-status--info {
  .available--text {
    color: #14ac7f;
  }
  .in-use--text {
    color: #c79f32;
  }
  .disabled--text {
    color: #a5b6c6;
  }
  .text-size {
    font-size: 0.95rem;
    font-weight: 500;
  }
  .btn--blue {
    background-color: #3c7cdd21;
    color: #6f9de3;
  }
  .btn--red {
    background-color: #e85c4826;
    color: #f5604c;
  }
}

//reservations tab
.reservations-list--table {
  .list-icon {
    margin-right: 1.5rem;
    font-size: 1.2rem;
    color: #5f7384;
    cursor: pointer;
  }
  .reservations-vehicle-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .reservation--border {
    border-bottom-width: 0;
  }
  .reservations-name-list {
    display: flex;
    align-items: center;
    .list-avatar {
      height: 1.7rem;
      width: 1.7rem;
    }
  }
  .reservations-dropdown {
    .edit--block {
      display: flex;
      align-items: center;
      cursor: pointer;
      .edit-text {
        font-style: normal;
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.125rem;
        color: $input-value-color;
        padding-left: 1rem;
      }
    }
    .delete--block {
      display: flex;
      align-items: center;
      cursor: pointer;
      .delete-text {
        font-style: normal;
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.125rem;
        color: #e12f17;
        padding-left: 1rem;
      }
    }
  }
}
//stop Transaction
.stop-transaction_main {
  .stop-transaction-header__block {
    padding: 1rem 0;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #2e3841;
    .stop-transaction-name {
      font-size: 1.25rem;
    }
    .close-btn {
      padding: 0.1rem;
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        background-color: black;
      }
    }
  }
  .stop-transaction_form {
    padding: 1rem 2rem;
    .stop-transaction_button {
      text-align: right;
      .stop-transaction-btn {
        padding: 0.8rem 1.7rem;
        font-weight: 500;
      }
      .cancel_btn {
        margin-right: 1rem;
        background-color: transparent;
        border-color: transparent;
        color: $primary-btn-color;
        &:hover {
          background-color: #36303a;
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

//review tab
.review-list__main {
  .review-list--block {
    margin-bottom: 1rem;
    .review-list-card {
      background: #213242;
      border-radius: 6px;
    }
    .review-list-card-wrapper {
      display: flex;
      align-items: flex-start;
      .review-avatar {
        height: 3.438rem;
        width: 3.438rem;
      }
      .review-list-content {
        display: flex;
        flex-direction: column;
        margin-left: 0.5rem;
        .review-list-name {
          font-style: normal;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.25rem;
          color: $input-value-color;
          margin-bottom: 0.5rem;
        }
        .review--rating-block {
          display: flex;
          align-items: center;
          margin-bottom: 0.5rem;
          .review-rating-star__block {
            margin-right: 1rem;
            svg {
              margin-right: 0.2rem;
            }
          }
          .review-rating__count {
            color: #788a9b;
            font-weight: normal;
            font-size: 0.75rem;
            line-height: 0.938rem;
          }
        }
        .review-list-text {
          font-style: normal;
          font-weight: normal;
          font-size: 0.813rem;
          line-height: 1.125rem;
          color: #8d9dac;
        }
      }
    }
  }
}

//contact Person Form.
.contactPerson-form__main {
  .contactPerson-form-header__block {
    padding: 1rem 0;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #2e3841;
    .contactPerson-header-name {
      font-size: 1.25rem;
    }
    .close-btn {
      padding: 0.1rem;
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        background-color: black;
      }
    }
  }
  .contactPerson-form-body__block {
    padding: 1rem 2rem;
    .contactPerson-form--block {
      .form_btn--block {
        text-align: right;
        margin-top: 1rem;
        .form--btn {
          padding: 0.8rem 1.7rem;
          font-weight: 500;
        }
        .cancel_btn {
          margin-right: 1rem;
          border-color: transparent;
          color: $primary-btn-color;
          background-color: #36303a;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}
