.dashboard-details__main {
  .dashboard-details--inner {
    .dashboard-details__block {
      margin-bottom: 1rem;
    }
    .dashboard-table__main {
      .main-card-container {
        margin-bottom: 1.5rem;
        .dashboard-realTime_event_main {
          .dashboard-realTime_event_table {
            .realTime_event-header_name {
              border-bottom: 1px solid #29343e;
              margin-bottom: 1rem;
              display: flex;
              justify-content: space-between;
              .dashboard-action--block {
                .dashboard-action--btn {
                  font-weight: 400;
                  font-size: 0.85rem;
                  padding: 0.5rem 1.7rem;
                  color: #e12f17;
                  cursor: pointer;
                  &:focus {
                    box-shadow: none;
                  }
                }
              }
            }
            .realTime_event-table {
              overflow-x: hidden;
              max-height: 350px;
              &.scrollable {
                overflow-y: auto;
              }
            }
          }
        }
      }
      .dashboard-energySold_chart_main {
        .dashboard-energySold_chart_inner {
          display: flex;
          margin-bottom: 1rem;
          .dashboard-energySold_chart_block {
            display: flex;
            flex-grow: 1;
            align-items: center;
            .dashboard-energySold_name {
              margin-right: 1.5rem;
            }
            .dashboard-energySold__btn {
              .dashboard-energySold-inner-btn {
                border: none;
                font-size: 0.85rem;
                background: #293d4f;
              }
            }
          }
          .dashboard-energySold_chart_content {
            display: flex;
            align-items: center;
            .dashboard-energySold__days {
              font-size: 0.875rem;
              line-height: 1.125rem;
              color: #ffffff;
              margin-right: 1rem;
            }
            .dashboard-energySold_days {
              margin-right: 1rem;
              font-size: 0.875rem;
              line-height: 1.125rem;
              color: #8d949b;
              cursor: pointer;
            }
            .dashboard-energySold_line {
              border: 1px solid #29343e;
              height: 1rem;
              margin: 0 0.5rem;
            }
            .dashboard-energySold_filter_icon {
              display: flex;
              align-items: center;
              color: #8d949b;
              svg {
                margin-left: 1rem;
              }
              .dashboard-energySold_filter_name {
                margin-left: 0.5rem;
              }
            }
          }
        }
        .dashboard-energySold_chart-img {
          .energySold_chart {
            height: auto;
            width: 100%;
          }
        }
      }
      .dashboard-bookingCount_chart_main {
        .dashboard-bookingCount_chart_inner {
          display: flex;
          margin-bottom: 1rem;
          .dashboard-bookingCount_chart_block {
            display: flex;
            flex-grow: 1;
            color: #ffffff;
            align-items: center;
            .dashboard-energySold_name {
              margin-right: 1.5rem;
            }
          }
          .dashboard-bookingCount_chart_content {
            display: flex;
            align-items: center;
            .dashboard-bookingCount__days {
              font-style: normal;
              font-weight: normal;
              font-size: 0.875rem;
              line-height: 1.125rem;
              color: #ffffff;
              margin-right: 1rem;
            }
            .dashboard-bookingCount_days {
              margin-right: 1rem;
              font-style: normal;
              font-weight: normal;
              font-size: 0.875rem;
              line-height: 1.125rem;
              color: #8d949b;
              cursor: pointer;
            }
          }
        }
        .dashboard-bookingCount_chart-img {
          .bookingCount_chart {
            height: auto;
            width: 100%;
          }
        }
      }
      .dashboard-charger-chart_main {
        .dashboard-charger-chart_inner {
          .dashboard-charger-chart_name {
            margin-bottom: 1.5rem;
          }
          .charger-chart_img_block {
            height: 17rem;
            display: flex;
            justify-content: center;
            .charger_chart {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .dashboard__sassClient__main {
        .dashboard__saasClient_card {
          width: 100%;
          border-bottom: 1px solid #29343e;
          margin-bottom: 1rem;
        }
        .dashboard_inner_saasClient {
          .dashboard__top_name {
            margin-bottom: 1.5rem;
          }
        }
      }
      .dashboard-oem-breakDown-chart_main {
        .dashboard-oem-breakDown-chart_inner {
          .dashboard-oem-breakDown-chart_name {
            margin-bottom: 1.5rem;
          }
          .oem-breakDown_chart-img_block {
            height: 17rem;
            display: flex;
            justify-content: center;
            .oem-breakDown_chart {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .dashboard-recent--main {
        .dashboard-recent--inner {
          .dashboard-recent--block {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1.5rem;
            .dashboard-recent--block_content {
              font-weight: 400;
              font-size: 0.85rem;
              color: #e12f17;
            }
          }
        }
        .connector-wrapper {
          display: flex;
          .connector-circle {
            color: #109b72;
            border: 1px #109b72 solid;
            border-radius: 50%;
            padding: 0px 9px;
            font-size: 20px;
            margin-right: 10px;
          }
          .available {
            color: #109b72;
            border: 1px #109b72 solid;
          }
          .charging {
            color: #3b7ddd;
            border: 1px #3b7ddd solid;
          }
          .unavailable {
            color: #9b1010;
            border: 1px #9b1010 solid;
          }
        }
        .sub-table-head-row,
        .sub-table-body-row {
          border-bottom: 1px solid rgb(96 96 96 / 13%);
        }
        .sub-table-head-row th {
          padding: 0.938rem 1.563rem;
          color: rgba(255, 255, 255, 0.5);
          font-size: 0.875rem;
          font-weight: normal;
        }
        .sub-table-body-row .available {
          color: #109b72;
        }
        .sub-table-body-row .charging {
          color: #3b7ddd;
        }
        .sub-table-body-row .unavailable {
          color: #9b1010;
        }
      }
    }
  }
}

//Card design
.dashboard-card {
  background: #1b2a38;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  .dashboard-detail-box {
    display: flex;
    align-items: center;
    .dashboard-icon {
      margin-right: 1.5rem;
      .session-icon {
        background: rgba(60, 124, 221, 0.1);
        border-radius: 8px;
        height: 4rem;
        width: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          height: 2rem;
          width: 2rem;
        }
      }
      .session-meta-icon {
        background: rgba(190, 33, 11, 0.13);
        border-radius: 8px;
        height: 4rem;
        width: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          height: 2rem;
          width: 2rem;
        }
      }
    }
    .dashboard__info {
      .title-session {
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 0.2rem;
      }
      .info-text {
        font-size: 0.8rem;
        font-weight: 400;
      }
    }
  }
}

//realTime Event Table
.realTime-event-item__main {
  .realTime-event-item--inner {
    display: flex;
    align-items: baseline;
    .realTime-event-content__main-block {
      flex: 1 1;
      width: 100%;
      padding: 0 1rem;
      margin-bottom: 0.2rem;
      .realTime-event-content--inner {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #29343e;
        padding: 1rem 0;
        .realTime-event-details--content-block {
          font-weight: 400;
          font-size: 12px;
          display: flex;
          align-items: center;
          flex: 1;
          .realTime-event-status--icon {
            margin: 0 0.7rem;
            color: #8d9dac;
          }
          .realTime-event-timing {
            color: #8d9dac;
          }
        }
      }
    }
  }
}

//saas client
.saasClient--item__main {
  display: flex;
  margin-bottom: 1.5rem;
  align-items: center;
  .saasClient--item__inner {
    display: flex;
    width: 100%;
    align-items: center;
    .saasClient_img {
      height: 2rem;
      width: 2rem;
    }
  }
  .saasClient-content__main-block {
    display: flex;
    .saasClient---number {
      margin-right: 0.5rem;
    }
    .saasClient-details-icon {
      svg {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }
}

//RecentTable component
.recentTable--item__main {
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .recentTable--item__inner {
    .recentTable--item__inner_block {
      align-items: center;
      display: flex;
      .recentTable--item__img {
        .recentTable_img {
          height: 2rem;
          width: 2rem;
        }
      }
      .recentTable--item_content {
        .recentTable-text {
          margin-bottom: 0.1rem;
        }
        .recentTable--item__inner_content {
          display: flex;
          color: #8d949b;
          .recentTable-details-icon {
            margin-right: 0.4rem;
          }
        }
      }
    }
  }
  .recentTable-content__block {
    .recentTable---date {
      margin-bottom: 0.1rem;
    }
    .recentTable---time {
      color: #8d949b;
    }
  }
}
