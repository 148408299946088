.data-report--main {
    .main-card-container {
      max-width: 99rem;
      .data-report__inner {
        .data-report__box {
          margin-bottom: 1rem;
          .data-report__row {
            .data-report-search-box {
              padding-top: 0.9rem;
              padding-left: 2rem;
              .report-search-icon {
                cursor: pointer;
              }
            }
            .log-filter-block {
              display: flex;
              padding: 0.75rem;
              .log-filter-icon {
                display: flex;
                align-items: center;
                .log-filter-label {
                  padding-left: 0.5rem;
                  font-size: 0.875rem;
                  font-weight: 600;
                }
              }
              .log-arrow__icon {
                color: $primary-btn-color;
                margin-left: auto;
              }
            }
            .log-filter-dropdown {
              min-width: 12rem;
              margin-right: 1rem;
              @media screen and (max-width: 1024px) {
                min-width: 10rem;
              }
              @media screen and (max-width: 768px) {
                min-width: 8rem;
              }
            }
            .report-search-button {
              border: none;
              cursor: pointer;
              border-radius: 5px;
              padding: 0.8rem 1rem;
              font-size: 0.9rem;
            }
            .report-search-excel-block {
              padding-left: 0.5rem;
              // padding-top: 0.5rem;
            }
            .report-search-box {
              // padding-top: 0.5rem !important;
              .advance-filter-btn {
                .primary-button {
                  padding: 0.2rem 1rem 0.2rem 1rem;
                }
              }
            }
            .excel-icon--block {
              padding-top: 0.6rem;
              .report-excel-icon {
                cursor: pointer;
                .download-table-xls-button {
                  color: #5dad5d;
                  background: transparent;
                  border: none;
                  svg {
                    height: 2rem;
                  }
                }
              }
            }
            // .data-report__table-download-icon {
            //   padding: 0.7rem;
            //   text-align: center;
            //   background: #182532;
            //   cursor: pointer;
            //   svg {
            //     font-size: 1.3rem;
            //     color: #be210b;
            //   }
            // }
            // .underline {
            //   border-bottom: 0.2rem solid #3d4e5f;
            //   width: 13rem;
            //   margin-left: 1.2rem;
            //   padding-top: 1rem;
            // }
            // .apply-content {
            //   display: flex;
            //   justify-content: center;
            //   align-items: center;
            //   padding: 0.6rem;
            //   .apply-label {
            //     color: $primary-btn-color;
            //     font-size: 0.875rem;
            //     font-weight: 700;
            //   }
            // }
          }
          .react-select .react-select__control {
            border: none;
            box-shadow: none !important;
            min-height: 32px;
            cursor: pointer;
            background: #182532;
            padding: 0.1rem;
            border-radius: 0 !important;
          }
        }
        .data-report__table {
          .record-list-table {
            .station-link, .charger-link {
              cursor: pointer;
            }
            .station-link:hover, .charger-link:hover {
              text-decoration: underline;
            }
            .dropdown-link {
              cursor: pointer;
            }
          }
          .data-report_inner {
            .json-data__block {
              white-space: unset !important;
              .json--data {
                max-width: 550px;
              }
            }

          }
          .connector-charging {
            //background: #3b7ddd;
            color: #3b7ddd!important;
            border-color: #3b7ddd!important;
          }
          .connector-unavailable {
            color: #9b1010!important;
            border-color: #9b1010!important;
          }
          .connector-wrapper{
            display: flex;
            .connector-circle{
                color: #109B72;
                border: 1px #109B72 solid;
                border-radius: 50%;
                padding: 0px 9px;
                font-size: 20px;
                margin-right: 10px;
            }
            .available{
                color: #109B72;
                border: 1px #109B72 solid;
            }
            .charging{
                color: #3b7ddd;
                border: 1px #3b7ddd solid;
            }
            .unavailable{
                color: #9b1010;
                border: 1px #9b1010 solid;
            }
          }
          .sub-table-head-row, .sub-table-body-row {
            border-bottom: 1px solid rgb(96 96 96 / 13%);
          }
          .sub-table-head-row th {
            padding: 0.938rem 1.563rem;
            color: rgba(255, 255, 255, 0.5);
            font-size: 0.875rem;
            font-weight: normal;
          }
          .sub-table-body-row .available{
            color: #109B72;
          }
          .sub-table-body-row .charging{
            color: #3b7ddd;
          }
          .sub-table-body-row .unavailable{
            color: #9b1010;
          }
        }
      }
    }
    .loader--block {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  
  // MultiSelectCheckBox Css
  // .css-1r4vtzz {
  //   background: #182532 !important;
  //   color: rgba(255, 255, 255, 0.5) !important ;
  //   padding: 0!important;
  //   // padding: 1rem 6rem 1rem 1rem !important;
  //   // display: flex !important;
  //   .css-1v99tuv {
  //     overflow: visible;
  //     padding: 1rem 5.5rem 1rem 1rem !important;
  //   }
  //   .css-1gpjby2{
  //     margin: 0rem 0.125rem !important;
  //   }
  // }
  
  // .css-1pcexqc-container {
  //   color: rgb(250, 250, 251) !important;
  //   background-color: #223343 !important;
  //   .css-1jllj6i-control {
  //     background: #223343 !important;
  //     border-color: #182532 !important ;
  //   }
  // }
  
  // .css-11unzgr {
  //   overflow-y: auto;
  //   &::-webkit-scrollbar {
  //     width: 6px;
  //     background-color: transparent;
  //   }
  //   &::-webkit-scrollbar-track {
  //     background-color: transparent;
  //   }
  //   &::-webkit-scrollbar-thumb {
  //     border-radius: 10px;
  //     background-color: rgba(195, 194, 197, 0.4);
  //   }
  
  //   .css-1gl4k7y {
  //     color: rgb(250, 250, 251) !important;
  //     font-weight: normal !important;
  //     font-size: 0.9rem !important;
  //     padding: 0.5rem 4.9rem;
  //   }
  // }
  // .css-48ayfv {
  //   background-color: #182531 !important;
  //   color: rgba(255, 255, 255, 0.5) !important ;
  //   padding: 0rem !important;
  //   // padding: 0.75rem 3rem !important;
  //   .css-1v99tuv{
  //     // overflow: visible;
  //     padding:1rem 5.5rem 1rem 1rem !important;
  //   }
  //   .css-1gpjby2{
  //     margin: 0rem 0.125rem !important;
  //   }
  // }
  // .css-1qprcsu-option {
  //   background-color: #223343 !important;
  //   color: rgb(250, 250, 251) !important;
  //   font-weight: normal !important;
  //   font-size: 0.9rem !important;
  // }
  // .css-1vr111p-option {
  //   font-size: 0.9rem !important ;
  // }
  // .css-1vr111p-option:hover {
  //   background-color: #223343 !important ;
  // }
  // .css-1gpjby2 {
  //   svg {
  //     stroke: currentcolor;
  //     fill: currentcolor;
  //     // margin-left: 3.5rem;
  //     width: 12px;
  //     height: 12px;
  //     padding: 0 1rem 0 0.5rem;
  //     stroke-width: 0;
  //     color: rgb(244, 63, 63);
  //     background: url('../../../svg/general/down-arrow-red.svg') no-repeat center;
  //     background-size: contain;
  //     padding: 0rem 1.5rem 0rem 1rem!important
  //   }
  // }
  // .css-48ayfv {
  //   .css-1v99tuv {
  //     overflow: visible;
  //     padding: 1rem 5.5rem 1rem 1rem;
  //   }
  // }
  // }
  // //booking-history
  // .booking-history-list--main {
  //   .booking-history-list-box {
  //     margin-bottom: 1rem;
  //     .booking-history-row {
  //       margin: 0;
  //       .history-list-filter {
  //         background: #182532;
  //         border: 1px solid #1b2a38;
  //         box-sizing: border-box;
  //         padding: 0.7rem;
  //         cursor: pointer;
  //         text-align: center;
  //         margin-bottom: 0.5rem;
  //       }
  //       .booking-history-date-picker {
  //         margin-bottom: 0.5rem;
  //       }
  //       .booking-history-select--box {
  //         cursor: pointer;
  //         .booking-history-label {
  //           font-style: normal;
  //           font-weight: normal;
  //           font-size: 0.813rem;
  //           color: #ffffff;
  //           line-height: 1rem;
  //           .booking-history-select-input {
  //             > div {
  //               background: #182532;
  //               border: 1px solid #1b2a38;
  //               box-sizing: border-box;
  //               border-radius: 0 !important;
  //               padding: 0.2rem;
  //             }
  //             .css-qc6sy-singleValue,
  //             .css-14el2xx-placeholder {
  //               margin-left: 3rem;
  //               font-style: normal;
  //               font-weight: normal;
  //               font-size: 0.813rem;
  //               line-height: 1rem;
  //               color: rgba(255, 255, 255, 0.5);
  //             }
  //             .css-6j8wv5-Input > button,
  //             input,
  //             optgroup,
  //             select,
  //             textarea {
  //               margin-left: 3rem !important;
  //               font-style: normal !important;
  //               font-weight: normal !important;
  //               font-size: 0.813rem !important;
  //               line-height: 1rem !important;
  //               color: rgba(255, 255, 255, 0.5) !important;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  
  // //charging-history
  // .charging-history-list--main {
  //   .charging-history-list-box {
  //     margin-bottom: 1rem;
  //     .charging-row {
  //       margin: 0;
  //       .charging-list-filter {
  //         background: #182532;
  //         border: 1px solid #1b2a38;
  //         box-sizing: border-box;
  //         padding: 0.7rem;
  //         cursor: pointer;
  //         text-align: center;
  //         margin-bottom: 0.5rem;
  //       }
  //       .charging-history-date-picker {
  //         margin-bottom: 0.5rem;
  //       }
  //     }
  //   }
  // }
  
  // //station-charging-summary
  // .station-charging-summary-list--main {
  //   .station-charging-summary-list-box {
  //     margin-bottom: 1rem;
  //     .station-charging-summary-row {
  //       margin: 0;
  //       .station-charging-list-filter {
  //         background: #182532;
  //         border: 1px solid #1b2a38;
  //         box-sizing: border-box;
  //         padding: 0.7rem;
  //         cursor: pointer;
  //         text-align: center;
  //         margin-bottom: 0.5rem;
  //       }
  //       .station-charging-summary-date-picker {
  //         margin-bottom: 0.5rem;
  //       }
  //       .station-charging-summary-select--box {
  //         cursor: pointer;
  //         .station-charging-summary-label {
  //           font-style: normal;
  //           font-weight: normal;
  //           font-size: 0.813rem;
  //           color: #ffffff;
  //           line-height: 1rem;
  //           .station-charging-summary-select-input {
  //             > div {
  //               background: #182532;
  //               border: 1px solid #1b2a38;
  //               box-sizing: border-box;
  //               border-radius: 0 !important;
  //               padding: 0.2rem;
  //             }
  //             .css-qc6sy-singleValue,
  //             .css-14el2xx-placeholder {
  //               margin-left: 3rem;
  //               font-style: normal;
  //               font-weight: normal;
  //               font-size: 0.813rem;
  //               line-height: 1rem;
  //               color: rgba(255, 255, 255, 0.5);
  //             }
  //             .css-6j8wv5-Input > button,
  //             input,
  //             optgroup,
  //             select,
  //             textarea {
  //               margin-left: 3rem !important;
  //               font-style: normal !important;
  //               font-weight: normal !important;
  //               font-size: 0.813rem !important;
  //               line-height: 1rem !important;
  //               color: rgba(255, 255, 255, 0.5) !important;
  //             }
  //           }
  //         }
  //       }
  //       .station-charging-summary-download-icon {
  //         padding: 0.7rem;
  //         text-align: center;
  //         background: #182532;
  //         cursor: pointer;
  //         svg {
  //           font-size: 1.3rem;
  //           color: #be210b;
  //         }
  //       }
  //     }
  //   }
  // }
  
  // .css-1r4vtzz {
  //   background: #182532 !important;
  //   color: rgba(255, 255, 255, 0.5) !important ;
  //   padding: 0rem 2rem 0rem 0rem !important;
  //   // padding: 1rem 6rem 1rem 1rem !important;
  //   // display: flex !important;
  //   .css-1v99tuv {
  //     // overflow: visible;
  //     padding: 1rem 9.5rem 1rem 1rem !important;
  //   }
  //   .css-1gpjby2 {
  //     padding: 1rem 1.5rem 1rem 1rem !important;
  //     // margin: 0rem 3rem;
  //   }
  // }
  
  // .css-1pcexqc-container {
  //   color: rgb(250, 250, 251) !important;
  //   background-color: #223343 !important;
  //   .css-1jllj6i-control {
  //     background: #223343 !important;
  //     border-color: #182532 !important ;
  //   }
  // }
  
  // .css-11unzgr {
  //   overflow-y: auto;
  //   &::-webkit-scrollbar {
  //     width: 6px;
  //     background-color: transparent;
  //   }
  //   &::-webkit-scrollbar-track {
  //     background-color: transparent;
  //   }
  //   &::-webkit-scrollbar-thumb {
  //     border-radius: 10px;
  //     background-color: rgba(195, 194, 197, 0.4);
  //   }
  
  //   .css-1gl4k7y {
  //     color: rgb(250, 250, 251) !important;
  //     font-weight: normal !important;
  //     font-size: 0.9rem !important;
  //     padding: 0.5rem 4.9rem;
  //   }
  // }
  // .css-48ayfv {
  //   background-color: #182531 !important;
  //   color: rgba(255, 255, 255, 0.5) !important ;
  //   padding: 0rem !important;
  //   // padding: 0.75rem 3rem !important;
  //   .css-1v99tuv {
  //     // overflow: visible;
  //     padding: 1rem 9.5rem 1rem 1rem;
  //   }
  //   .css-1gpjby2 {
  //     padding: 1rem 1.5rem 1rem 1rem !important;
  //   }
  // }
  // .css-1qprcsu-option {
  //   background-color: #223343 !important;
  //   color: rgb(250, 250, 251) !important;
  //   font-weight: normal !important;
  //   font-size: 0.9rem !important;
  // }
  // .css-1vr111p-option {
  //   font-size: 0.9rem !important ;
  // }
  // .css-1vr111p-option:hover {
  //   background-color: #223343 !important ;
  // }
  // .css-1gpjby2 {
  //   svg {
  //     stroke: currentcolor;
  //     fill: currentcolor;
  //     // margin-left: 3.5rem;
  //     width: 12px;
  //     height: 12px;
  //     padding: 0 1rem 0 0.5rem;
  //     stroke-width: 0;
  //     color: rgb(244, 63, 63);
  //     background: url('../../../svg/general/down-arrow-red.svg') no-repeat center;
  //     background-size: contain;
  //     // padding: 0rem 1.5rem !important
  //   }
  // }
  // .css-48ayfv {
  //   .css-1v99tuv {
  //     // overflow: visible;
  //     padding: 1rem 9.5rem 1rem 1rem;
  //   }
  // }
  // // }
  