.dataTimePicker-floating-label-input-group {
  display: flex;
  column-gap: 1.25rem;
  @media screen and (max-width: 768px) {
    column-gap: 2rem;
  }
  .input-field {
    max-width: 100%;
    width: 100%;
    margin-bottom: 0;
    @media screen and (max-width: 1024px) {
      width: 15rem;
    }
    @media screen and (max-width: 768px) {
      width: 15rem;
    }
    .dateTimepicker-floating-label {
      font-style: normal;
      font-weight: normal;
      font-size: 0.813rem;
      color: #ffffff;
      line-height: 1rem;
    }
  }

  .rdt input {
    background: #182532;
    border: 1px solid #1b2a38;
    box-sizing: border-box;
    padding: 1rem 0.5rem 1rem 3.35rem;
    font-size: 0.813rem;
    border-radius: unset !important;
    text-align: start;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.5);
    font-family: 'IBM Plex Sans', sans-serif;
    z-index: 1;
    outline: none;
    cursor: pointer;
    &:focus {
      border: none;
      box-shadow: unset;
    }
  }
  .rdtPicker {
    background: #565b6e;
    border: 1px solid gray;
    padding: unset;
    .rdtDays {
      background: #202028;
    }
    .rdtTime {
      background: #202028;
    }
  }
}
