.profile-form-page__main {
  .profile-form-page--inner {
    padding: 1rem;
    padding-bottom: 5rem;
    .profile-form__block {
      .gender-field__block {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .radio-field {
          margin-right: 3rem;
        }
      }
      .save-btn--block {
        margin-top: 2.5rem;
        .profile-save-btn {
          padding: 0.7rem 2rem;
          margin-right: 1rem;
        }
      }
      .cancel-btn--block {
        margin-top: 2.5rem;
        .cancel-btn {
          margin-right: 1rem;
          background-color: transparent;
          border-color: transparent;
          color: $primary-btn-color;
          padding: 0.7rem 2rem;
          background-color: #36303a;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}
