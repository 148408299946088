.charger-detail-page__main {
  .charger-profileInfo {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    .charger-box-main {
      display: flex;
      .charger-box {
        display: inline-flex;
        align-items: center;
        margin-right: 1rem;
        .charger-icon {
          display: flex;
          padding: 0.5rem 1rem;
          background: rgba(232, 92, 72, 0.1);
          height: 4rem;
          width: 4rem;
          color: #be210b;
          .plug--icon {
            height: 3rem;
            width: 2rem;
          }
        }
        .charger-profileInfo-wrapper {
          margin-left: 1rem;
          .charger--name {
            display: flex;
            align-items: center;
            margin-bottom: 0.5rem;
            font-size: 1.625rem;
            font-weight: 500;
            line-height: 2.063rem;
          }
          .charger-meta-info {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            font-style: normal;
            font-weight: normal;
            font-size: 0.875rem;
            line-height: 1.125rem;
            color: #8d949b;
            .copy-clipboard__block {
              margin-left: 1rem;
              .copy-btn {
                border: none;
                background: #ffffff21;
                color: white;
                padding: 0.3rem;
                border-radius: 4px;
                &:hover {
                  background: #ffffff57;
                }
                .copy--icon {
                  height: 1.1rem;
                  width: 1.1rem;
                }
              }
            }
            .qr-code--btn {
              margin-left: 1rem;
              cursor: pointer;
            }
          }
        }
      }
      .charger-status-action__block {
        display: inline-flex;
        .charger-status--block {
          .charger--status {
            padding: 0.5rem;
            display: flex;
            align-items: center;
            flex-direction: column;
            .dot--icon {
              display: inline-flex;
              height: 1.5rem;
              width: 1.5rem;
            }
          }
        }
        .charger-action--block {
          margin-left: 2rem;
          .charger-action--btn {
            border-radius: 5px;
            border: none;
            font-weight: 400;
            font-size: 0.85rem;
            padding: 0.5rem 1.7rem;
            &:focus {
              box-shadow: none;
            }
          }
        }
      }
    }
    .charger-dropdown_btn {
      margin-left: auto;
      .charger-dropdown-icon > svg {
        color: #5f7384;
        font-size: 2rem;
      }
      .delete--charger {
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
          font-size: 1.2rem;
        }
        .delete-charger-text {
          font-style: normal;
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 1.125rem;
          color: #e12f17;
          padding-left: 1rem;
        }
      }

      .edit--charger {
        display: flex;
        align-items: center;
        cursor: pointer;

        svg {
          font-size: 1.2rem;
        }

        .edit-charger-text {
          font-style: normal;
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 1.125rem;
          color: #ffffff;
          padding-left: 1rem;
        }
      }
    }

    .d-flex {
      margin-left: auto;
    }
  }
  .charger-data-main {
    background: #1b2a38;
    border: 1px solid #2f3c47;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 1rem;
    margin-bottom: 1rem;
    .charger-data-inner {
      padding: 1rem;
      .charger-OEM {
        color: #8d949b;
        margin-right: 0.4rem;
      }
    }
    .charger-data-main_inner {
      padding: 1rem;
      .Charger-speed {
        color: #8d949b;
        margin-right: 0.4rem;
      }
    }
  }
  .charger-box-pages_main {
    .charger-box-pages_inner {
      .opration-timing__block {
        background: #1b2a38;
        border: 1px solid #2f3c47;
        box-sizing: border-box;
        border-radius: 10px;
        margin: 0;
        padding: 1.5rem 1.2rem;
        margin-bottom: 1rem;
        .Charger-operation_timing {
          margin-bottom: 1.5rem;
          .charger-operation {
            color: #8d949b;
          }
        }
        .operation-timing__inner {
          display: flex;
          align-items: center;
          .watch--icon {
            margin-right: 0.5rem;
            .watch-icon {
              color: #3c7cdd;
              height: 1.5rem;
              width: 1.5rem;
            }
          }
          .oprational-timing-info {
            font-size: 0.95rem;
            .dot-icon {
              margin: 0 0.2rem;
            }
          }
        }

        .charger-watch_icon {
          color: #3c7cdd;
        }
      }
      .charger-box_block {
        background: #1b2a38;
        border: 1px solid #2f3c47;
        box-sizing: border-box;
        border-radius: 10px;
        margin-bottom: 1rem;
        padding: 1.5rem 1.2rem;
        .charger-time {
          display: flow-root;
          .charger--status {
            margin-bottom: 1rem;
            .dot--icon {
              height: 1.5rem;
              width: 1.5rem;
            }
          }
        }
        .charging-time_left {
          margin-bottom: 1rem;
          .charger-Tltc {
            color: #8d949b;
            margin-right: 0.5rem;
            .charger-watch_icon {
              margin-right: 0.7rem;
              .watch_icon {
                color: #3c7cdd;
                height: 1.5rem;
                width: 1.5rem;
              }
            }
          }
        }
        .charger-energy {
          .charger-ectn {
            color: #8d949b;
            margin-right: 0.5rem;
            .charger-enery_icon {
              margin-right: 0.7rem;
              .enery_icon {
                color: #3c7cdd;
                height: 1.5rem;
                width: 1.5rem;
              }
            }
          }
        }
      }
    }
    .charger-data-page-main {
      .charger-colom-inner {
        background: #1b2a38;
        border: 1px solid #2f3c47;
        box-sizing: border-box;
        border-radius: 10px;
        margin: 0;
        padding: 1.5rem 1.2rem;
        margin-bottom: 1rem;
        .charger-pluge {
          color: #8d949b;
        }
        .charger-four {
          display: flex;
          justify-content: space-between;
          .charger-four-btn {
            padding: 0.2rem 0.4rem;
            background-color: rgba(190, 33, 11, 0.13);
            border-radius: 8px;
            font-style: normal;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.125rem;
            color: #ffffff;
          }
        }
      }
      .charger-comments_notes {
        background: #1b2a38;
        border: 1px solid #2f3c47;
        box-sizing: border-box;
        border-radius: 10px;
        margin: 0;
        padding: 1.5rem 1.2rem;
        display: flex;
        flex-direction: column;
        .charger-Comment {
          color: #8d949b;
          margin-bottom: 1rem;
        }
      }
    }
  }
  .loader--block {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/** Charger PlugeInfo component */
.charger-name_icon-data {
  padding: 1rem 0;
  .charger-name--icon {
    background: #1b2a38;
    border: 1px solid #2f3c47;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 1.1rem 1.5rem;
    display: flex;
    align-items: center;
    .charger-round_icon {
      margin-right: 0.7rem;
      svg {
        color: #3c7cdd;
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }
}

//QRCode-Modal
.qr-code-modal--main {
  .qr-code__block {
    padding: 0.5rem 0;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #2e3841;
    .qr-code-name {
      font-size: 1.25rem;
    }
    .qr-code-close-btn {
      padding: 0.1rem;
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        background-color: black;
      }
    }
  }
  .qrCode--img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
    .empty-qrCode {
      text-align: center;
      padding: 1rem;
    }
  }
  .qrCode--button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.5rem 0;
  }
}
