.sidebar-main {
  .react-sidebar__main {
    .pro-sidebar-inner {
      background: $primary-bg-color;
      .pro-sidebar-layout {
        overflow: hidden;
        .sidebar-header--block {
          height: 5.5rem;
          border-bottom: none;
          .sidebar__logo {
            display: flex;
            justify-content: center;
            padding: 0.5rem;
            height: 100%;
            .logo--img {
              cursor: pointer;
              height: auto;
              width: 11rem;
              img {
                height: 100%;
                width: 100%;
              }
            }
          }
          .small-sidebar-logo__block {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .small-logo--img {
              cursor: pointer;
              height: 3rem;
              width: 3rem;
              img {
                height: 100%;
                width: 100%;
                border-radius: 23px;
              }
            }
          }
        }
        .sidebar-content--block {
          height: calc(100% - 5.5rem - 57px);
          overflow-x: hidden;
          .side-menu-bar {
            padding: 0.5rem 0.7rem;
            .sidebar-menu-item {
              color: #8d9dac;
              margin-bottom: 0.2rem;
              .pro-inner-item {
                padding: 0.7rem;
                border-radius: 8px;
                font-weight: 300;
                font-size: 0.9rem;
                line-height: 2;
                &:hover {
                  background-color: #314456;
                }
                .pro-icon-wrapper {
                  font-size: 1rem;
                }
              }
              &.active {
                .pro-inner-item {
                  color: #ffffff;
                  background-color: #be210b40 !important;
                }
              }
            }
            .notification--number {
              background: #be210b;
              margin-left: 7px;
              padding: 2.5px 8.3px 2.5px 4px;
              border-radius: 1rem;
              font-size: 12px;
              font-weight: 400;
              color: white;
            }
            .sidebar-sub-menu-item {
              &.open {
                .pro-inner-item {
                  .pro-arrow-wrapper {
                    .pro-arrow {
                      transform: rotate(180deg);
                    }
                  }
                }
              }
              .pro-inner-item {
                .pro-arrow-wrapper {
                  .pro-arrow {
                    width: 0;
                    height: 0;
                    border: 0;
                    padding: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid #8d9dac;
                    transform: unset;
                  }
                }
              }
              .pro-inner-list-item {
                background: inherit;
                padding-left: 1rem;
                ul {
                  padding: 0;
                }
                .popper-inner {
                  padding: 1rem;
                  .pro-menu-item {
                    .pro-inner-item {
                      padding-left: 1rem;
                    }
                  }
                }
                .pro-menu-item {
                  .pro-inner-item {
                    padding-left: 2rem;
                    .pro-item-content {
                      white-space: unset;
                    }
                  }
                }
              }
              .sub-menu-inner-menu-item {
                &.open {
                  .pro-inner-item {
                    .pro-arrow-wrapper {
                      .pro-arrow {
                        transform: rotate(180deg) !important;
                      }
                    }
                  }
                }
                .pro-inner-item {
                  padding-left: 0.53rem !important;
                }
                .pro-inner-list-item {
                  background: inherit;
                  padding-left: 1rem;
                  ul {
                    padding: 0;
                  }
                  li {
                    padding-left: 0.9rem;
                  }
                  .popper-inner {
                    padding: 1rem;
                    .pro-menu-item {
                      .pro-inner-item {
                        padding-left: 1.5rem !important;
                      }
                    }
                  }
                }
                .pro-inner-item {
                  .pro-arrow-wrapper {
                    .pro-arrow {
                      width: 0;
                      height: 0;
                      border: 0;
                      padding: 0;
                      border-left: 5px solid transparent;
                      border-right: 5px solid transparent;
                      border-top: 5px solid #8d9dac;
                      transform: unset !important;
                    }
                  }
                }
              }
            }
          }
        }
        .sidebar-footer--block {
          &__inner {
            width: 100%;
            padding: 1rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            span {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
