.setting-page__main {
  .page--info {
    display: flex;
    align-items: center;
    .user-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .user-name {
        font-style: normal;
        font-weight: 500;
        font-size: 1.625rem;
        line-height: 2.063rem;
        color: $input-value-color;
        margin-bottom: 0.7rem;
      }
      .user-info {
        font-style: normal;
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.125rem;
        color: $input-value-color;
      }
    }
  }
  .loader--block {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// Profile-tab
.profile-details__main {
  .profile-details-inner {
    .profile--content {
      margin-bottom: 1rem;
      .profile-details--wrapper {
        margin-bottom: 1.5rem;
      }
    }
  }
}

//change Password
.change-password-form_main {
  padding: 0 2rem;
  .change-password-form_block {
    .change-password-form__inner {
      .form-input-group {
        margin-bottom: 1.7rem;
      }
    }
    .change-password-save--btn--block {
      .save--btn {
        padding: 1rem 2.5rem;
        background: #be210b;
        border-radius: 8px;
      }
    }
  }
}

//Roles&Access tab
.role-access-page__main {
  .table-responsive {
    overflow: inherit !important;
    .role-access-name-list {
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      .user-avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1.7rem;
        width: 1.7rem;
      }
    }
    .role-access-role {
      .roles-select__box {
        margin: 0;
        width: 14rem;
        .react-select {
          .react-select__control {
            border: 1px solid #8d949b;
            border-radius: 4px !important;
            .react-select__value-container {
              padding: 0.1rem 0.5rem;
            }
          }
        }
      }
    }
    .role-access-charging-station-icon {
      color: lightskyblue;
      margin-right: 1rem;
      cursor: pointer;
    }
    .role-access-delete-icon {
      cursor: pointer;
      svg {
        height: 1.25rem;
        width: 1.375rem;
        color: #be210b;
      }
    }
    @media (max-width: 1024px) {
      .css-gzpaqu-container {
        min-width: 10rem;
      }
    }
  }
  .loader-block___ {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
