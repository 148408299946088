.list-filter-canvas__container {
  background-color: $primary-bg-color;
  color: #ffffff;
  .filter-canvas__inner {
    padding: 2rem;
    .canvas__header-block {
      padding: 1rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #2e3841;
      .header-name {
        font-size: 1.25rem;
        font-weight: 500;
      }
      .header-close-icon {
        cursor: pointer;
      }
    }
    .canvas__body-block {
      padding: 1rem 0;
      &--inner {
        .form-select-label {
          font-size: 0.95rem;
          font-weight: 400;
          margin-bottom: 0.7rem;
        }
        .filter-select-box {
          margin-bottom: 1.5rem;
        }
        .canvas-selection__block {
          margin-bottom: 1.5rem;
          .selection-block__inner {
            border: 1px solid #3b4a56;
            border-radius: 10px;
            padding: 0.3rem;
            .row {
              margin: 0;
              .col {
                padding: 0;
              }
            }
            .selection--btn {
              padding: 0.7rem 0.5rem;
              font-size: 0.95rem;
              color: $text-color-light;
              text-align: center;
              cursor: pointer;
              border-radius: 10px;
            }
            .active {
              background-color: #be210b26;
              color: #ffffff;
            }
          }
        }
        .canvas-btn__block {
          font-size: 0.95rem;
          .apply--btn {
            padding: 1rem 0.5rem;
            border-radius: 10px;
            margin-bottom: 1rem;
          }
          .reset--block {
            padding: 0.7rem;
            color: $text-color-light;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:hover {
              background-color: #be210b21;
            }
            .reset--icon {
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }
}
