.station-list-page__main {
  padding: 0.5rem;
  .search--block {
    margin-bottom: 1rem;
  }
  .map-station--outer-block {
    display: flex;
    .station-list__block-main {
      margin-right: 1.5rem;
      width: 330px;
      .station__count-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.9rem;
        padding-left: 1rem;
        margin-bottom: 1rem;
        .total-station {
          padding: 0.3rem;
          background-color: #be210b21;
          border-radius: 8px;
        }
      }
      .station-list__block-inner {
        height: calc(100vh - 18rem);
        padding-right: 0.3rem;
      }
      .station-list-item__main {
        cursor: pointer;
        padding: 1rem;
        background: $primary-bg-color;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03);
        border-radius: 6px;
        margin-bottom: 1rem;
        .station-item__inner {
          width: 255px;
          display: flex;
          align-items: flex-start;
          .station--icon-block {
            .station--icon {
              padding: 1.7rem 1rem;
              background: rgba(60, 124, 221, 0.1);
              border-radius: 5px;
              margin-right: 1rem;
              svg {
                height: 1.5rem;
                width: 1.5rem;
                color: #3c7cdd;
              }
            }
          }
          .station--info-block {
            width: 100%;
            flex: 1;
            .station--info_inner {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              .station--name {
                font-size: 1rem;
                color: #ffffff;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                margin-bottom: 0.3rem;
                width: auto;
                max-width: 15rem;
              }
              .station--rating-block {
                display: flex;
                align-items: center;
                margin-bottom: 0.5rem;
                .rating-star__block {
                  margin-right: 1rem;
                  svg {
                    margin-right: 0.2rem;
                  }
                }
                .rating__count {
                  color: #788a9b;
                  font-weight: normal;
                  font-size: 0.75rem;
                  line-height: 15px;
                }
              }
              .station--location-block {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #a5b6c6;
                font-weight: 300;
                font-size: 0.75rem;
                .location--name {
                  width: auto;
                  max-width: 6rem;
                }
              }
            }
            .station-active__main {
              margin-top: 0.6rem;
              color: #e12f17;
              font-size: 0.75rem;
              font-weight: 500;
              text-align: right;
              .station-active-text {
                padding: 0.5rem;
                background: rgba(232, 92, 72, 0.1);
              }
            }
          }
        }
      }
    }
    .map__block-main {
      width: 100%;
      flex: 1;
      background-image: url('https://s3-alpha-sig.figma.com/img/85da/2fab/81f52953d795e83abf43fa2d1356d35f?Expires=1644796800&Signature=Yr4wmTGmtGmiGNNeQLUeJ8IezNN-oQtGptOtwEtU2EIuNPdS0iJxKRITsInMKZtbEsdT7oIlnMQbp3TNdPQdCrBjx7Ra-MFUd7PpogTDAe6mkNyEqsEROfg1t3lR2hWuoCOzzxkdp4FCKQucr8yFF~qccbS9yRBfuzmVflLIVjlaDSWJ83v3jsR2Lm67ygfFUFpNbIdtsCIggjgrX32qNfBYnQVSIPhhoWc9y7CdgJeFT3c-2kFaANDeLuZ1bxcBD2kRYNZqGYoXA9EpZuTydtm3YAX3a3XVCBoy-T5AC2ONyPqf~tDaOnOwuyhvcOgzavPzuVxk4tQDWnTptIY5mA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA');
      border-radius: 6px;
      .marker-text {
        cursor: pointer;
        color: black;
      }
    }
  }
}

.station-list-filter-canvas__container {
  background-color: $primary-bg-color;
  color: #ffffff;
  .filter-canvas__inner {
    padding: 2rem;
    .canvas__header-block {
      padding: 1rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #2e3841;
      .header-name {
        font-size: 1.25rem;
        font-weight: 500;
      }
      .header-close-icon {
        cursor: pointer;
      }
    }
    .canvas__body-block {
      padding: 1rem 0;
      &--inner {
        .form-select-label {
          font-size: 0.95rem;
          font-weight: 400;
          margin-bottom: 0.7rem;
        }
        .filter-select-box {
          margin-bottom: 1.5rem;
        }
        .canvas-selection__block {
          margin-bottom: 1.5rem;
          .selection-block__inner {
            border: 1px solid #3b4a56;
            border-radius: 10px;
            padding: 0.3rem;
            .row {
              margin: 0;
              .col {
                padding: 0;
              }
            }
            .selection--btn {
              padding: 0.7rem 0.5rem;
              font-size: 0.95rem;
              color: $text-color-light;
              text-align: center;
              cursor: pointer;
              border-radius: 10px;
            }
            .active {
              background-color: #be210b26;
              color: #ffffff;
            }
          }
        }
        .canvas-btn__block {
          font-size: 0.95rem;
          .apply--btn {
            padding: 1rem 0.5rem;
            border-radius: 10px;
            margin-bottom: 1rem;
          }
          .reset--block {
            padding: 0.7rem;
            color: $text-color-light;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:hover {
              background-color: #be210b21;
            }
            .reset--icon {
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }
}
