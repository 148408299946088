$primary-bg-color: #1b2a38;
$secondary-bg-color: #223343;

$input-label-color: #ffffff80;
// $input-border-color: #2f3c47;
$input-border-color: #3b4a56; // custom-color
$input-value-color: #ffffff;
$input-disabled-color: #223444;
$input-focus-color: #979797;

$primary-btn-color: #be210b;
$secondary-btn-color: #36303a;
$secondary-btn-text: #f5604c;

$light-black-1: #182532;

$text-color-light: #8d949b;
