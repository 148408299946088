.add-vehicle-page-main {
  .vehicle-form-card {
    .card-body {
      padding: 2rem 2.5rem;
    }
    .vehicle-form__block {
      padding-bottom: 2rem;
      .vehicle-form__inner {
        padding: 1rem 0;
      }
      .import-vehicle-block {
        display: flex;
        .download-sample--block {
          display: flex;
          align-items: center;
          margin-left: 1rem;
          .download-sample-file {
            font-size: 0.9rem;
            color: #58d37d;
            cursor: pointer;
            font-weight: 500;
          }
        }
      }
      .vehicle-save--btn--block {
        .vehicle-save-btn {
          padding: 1rem 1.5rem;
        }
      }
    }
  }
}
